import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { OuterLink, ScrollDownBtn } from "../../components";
import config from "../../config";
import {
  HomeContainer,
  TopLeftBanner,
  RightLinkSection,
  MainLogo,
  DownloadLink,
  SNSLink,
  MainSection,
  MobileHome,
  MobileHomeButtons,
  MobileHomeButtonsLine1,
  MobileHomeButtonsLine2,
} from "./style";
import { getLocaleFromPath } from "../../utils/utils";
import { LANGS_MAP_FOR_ASSETS } from "../../utils/languages";
import mobileFBIcon from "../../assets/images/mobile/m-snk-btn-01.png";
import mobileDiscordIcon from "../../assets/images/mobile/m-snk-btn-02.png";
import Download from "../../components/Download";

export default function Home() {
  // query markdown by type in frontmatter
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "indexSlide" } } }) {
        nodes {
          fileAbsolutePath
          frontmatter {
            title
            date
            link
            image
            order
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale } = intl;
  const lang = LANGS_MAP_FOR_ASSETS[locale];

  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  const slides = nodes.filter(node => getLocaleFromPath(node.fileAbsolutePath) === locale);
  const sorted_slides = slides
    .sort((a, b) => new Date(b.frontmatter.date).getTime() - new Date(a.frontmatter.date).getTime())
    .slice(0, 3);

  return (
    <>
      <HomeContainer id="home">
        <TopLeftBanner lang={lang} />

        <MainSection>
          <MainLogo lang={lang} />
          <RightLinkSection>
            <Download href={config.homePage.downloadLinks.ios}>
              <DownloadLink platform={"ios"}>iOS Download</DownloadLink>
            </Download>
            <Download href={config.homePage.downloadLinks.android}>
              <DownloadLink platform={"android"}>Android Download</DownloadLink>
            </Download>
            <Download href={config.homePage.downloadLinks.pc}>
              <DownloadLink platform={"pc"}>
                <span>NoxPlayer</span>
                PC Download
              </DownloadLink>
            </Download>

            <OuterLink href={config.homePage.snsLinks.facebook[locale]}>
              <SNSLink sns={"facebook"}>FB Group</SNSLink>
            </OuterLink>
            <OuterLink href={config.homePage.snsLinks.discord[locale]}>
              <SNSLink sns={"discord"}>Discord</SNSLink>
            </OuterLink>
          </RightLinkSection>
        </MainSection>

        <MobileHome>
          <img
            src={require(`../../assets/images/mobile/kv-logo-m-${lang}.png`).default}
            alt="main-logo-mobile"
          />
          <MobileHomeButtons>
            <MobileHomeButtonsLine1>
              <Download href={config.homePage.downloadLinks.ios}>
                <img
                  src={require(`../../assets/images/mobile/apk-btn-ios-${lang}.png`).default}
                  alt="ios-download-mobile"
                />
              </Download>
              <Download href={config.homePage.downloadLinks.android}>
                <img
                  src={require(`../../assets/images/mobile/apk-btn-adrd-${lang}.png`).default}
                  alt="android-download-mobile"
                />
              </Download>
            </MobileHomeButtonsLine1>
            <MobileHomeButtonsLine2>
              <OuterLink href={config.homePage.snsLinks.facebook[locale]}>
                <img src={mobileFBIcon} alt="facebook-page" />
              </OuterLink>
              <Download id="mobile-pc-link" href={config.homePage.downloadLinks.pc}>
                <img
                  src={require(`../../assets/images/mobile/apk-btn-nox-${lang}.png`).default}
                  alt="pc-download-mobile"
                />
              </Download>
              <OuterLink href={config.homePage.snsLinks.discord[locale]}>
                <img src={mobileDiscordIcon} alt="discord-page" />
              </OuterLink>
            </MobileHomeButtonsLine2>
          </MobileHomeButtons>
        </MobileHome>

        <ScrollDownBtn />
      </HomeContainer>
    </>
  );
}
