import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import "../assets/css/global.css";
import {
  NavbarPC,
  NavbarMobile,
  LangSwitcher,
  ScrollToTop,
  Footer,
  MetaDataHead,
} from "../components";
import {
  Loading,
  AgeVerification,
  BirthYearVerification,
  Home,
  Intro,
  CharacterListWrapper,
  CharacterList,
} from "../views";
import { VerificationContext } from "../contexts";
import { useLoaded, useAgeConfirmed } from "../hooks";
import { LANGS_URLS_FOR_ROOT } from "../utils/languages";
import { BREAK_POINTS } from "../utils/style";

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  animation: fadeIn 1s;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  background: url("${process.env.GATSBY_ROOT_PATH}/index-page-bg.png") center top/cover no-repeat;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    background: url("${process.env.GATSBY_ROOT_PATH}/index-page-bg-mobile.png") center top/cover
      no-repeat;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100%;
  z-index: 1;
`;

const BirthYearVerificationContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100vw;
  min-height: -webkit-fill-available;
  top: 0px;
  left: 0px;
  // more than MenuWrapper
  z-index: 40;
`;

export default function Root() {
  const [isVerificationShow, setIsVerificationShow] = useState(false);
  const [verificationLink, setVerificationLink] = useState("");

  const { ageConfirmed, handleConfirm, handleDecline } = useAgeConfirmed();
  const { loaded } = useLoaded();

  // set language preference
  useEffect(() => {
    if (typeof window !== "undefined") {
      const arr = window.location.href.replace(/\/$/, "").split("/");
      const lang = arr[arr.length - 1];
      window.localStorage.setItem("gatsby-intl-language", lang);
    }
  }, []);

  const handleBirthYearConfirm = () => {
    window.open(verificationLink, "_blank", "noreferrer,noopener");
    setIsVerificationShow(false);
  };
  const handleBirthYearDecline = () => {
    setIsVerificationShow(false);
  };

  return (
    <VerificationContext.Provider
      value={{
        isVerificationShow,
        setIsVerificationShow,
        verificationLink,
        setVerificationLink,
      }}>
      {<Loading style={loaded ? { opacity: 0, zIndex: "-40" } : { opacity: 1, zIndex: "40" }} />}
      {!ageConfirmed ? (
        <AgeVerification handleConfirm={handleConfirm} handleDecline={handleDecline} />
      ) : (
        <>
          <MetaDataHead>
            {/* TODO: deal with video preload */}
            {/* <link
              rel="preload"
              href={`${process.env.GATSBY_ROOT_PATH}/op.mp4`}
              as="video"
              type="video/mp4"
              media="(min-width: 768px)"
            />
            <link
              rel="preload"
              href={`${process.env.GATSBY_ROOT_PATH}/op-mobile.mp4`}
              as="video"
              type="video/mp4"
              media="(max-width: 767px)"
            /> */}
          </MetaDataHead>
          {isVerificationShow && (
            <BirthYearVerificationContainer>
              <BirthYearVerification
                handleConfirm={handleBirthYearConfirm}
                handleDecline={handleBirthYearDecline}
              />
            </BirthYearVerificationContainer>
          )}
          <RootContainer>
            <ImageContainer />
            <ContentContainer id="content-container">
              <LangSwitcher destination={LANGS_URLS_FOR_ROOT} />
              <NavbarPC />
              <NavbarMobile />
              <ScrollToTop />

              <Home />
              <Intro />
              <CharacterListWrapper>
                <CharacterList />
              </CharacterListWrapper>
              <Footer />
            </ContentContainer>
          </RootContainer>
        </>
      )}
    </VerificationContext.Provider>
  );
}
