import styled from "@emotion/styled";
import { BREAK_POINTS } from "../../utils/style";
import sectionBg from "../../assets/images/game-intro-bg.png";

const CharacterListBg = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  padding: 0 calc((100% - 1300px) / 2);
  z-index: -1;

  > img:first-of-type {
    padding-top: 80px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    padding: 0 10px;
    top: 200px;

    > img {
      width: 200px;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    display: none;
  }
`;

const CharacterListSection = styled.section`
  margin: 30px auto 0;
  text-align: center;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    margin-top: 15px;
  }
`;

const CharacterListInnerSection = styled.section`
  a {
    display: inline-block;
    width: fit-content;
    height: fit-content;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 98vw;
    height: 34vw;
    max-height: 150px;
    background: url(${sectionBg}) center/contain no-repeat;
    margin: 0 auto;

    a {
      width: 25%;
    }
  }
`;

const CharacterListSectionTitle = styled.h3`
  color: #ffffff;
  font-size: 44px;
  font-style: italic;
  font-weight: 700;
  text-shadow: 4px 6px 1px rgba(255, 0, 12, 0.35), 0 0 10px #ff1552, 0 0 20px #ff1552;
  text-align: center;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    font-size: 28px;
    text-shadow: 2px 4px 1px rgba(255, 0, 12, 0.35), 0 0 4px #ff1552, 0 0 14px #ff1552;
  }

  @media screen and (max-width: ${BREAK_POINTS.sm}) {
    font-size: 24px;
  }
`;

const CharacterPreview = styled.img`
  margin: 10px 15px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 90%;
    max-width: 100px;
    margin: 3px;
  }
`;

export {
  CharacterListBg,
  CharacterListSection,
  CharacterListInnerSection,
  CharacterListSectionTitle,
  CharacterPreview,
};
