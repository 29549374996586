import styled from "@emotion/styled";
import { containerStyle, BREAK_POINTS } from "../../utils/style";
import rightSectionBg from "../../assets/images/home/side-menu-bg-00.png";
import mobileBtnBg from "../../assets/images/mobile/apk-btn-bg.png";

const HomeContainer = styled.main`
  ${containerStyle}
  padding-top: 20px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    padding-top: 10px;
  }

  @media screen and (min-width: 768px) and (max-height: ${BREAK_POINTS.md}) {
    height: 768px;
  }
`;

const TopLeftBanner = styled.div`
  position: absolute;
  width: 290px;
  height: 137px;
  background: ${props =>
      `url(${require(`../../assets/images/home/kv-banner-lt-${props.lang}.png`).default})`}
    center/contain no-repeat;
  margin: 0 20px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    position: relative;
    width: 145px;
    height: 64px;
    margin: 0 10px;
  }
`;

const MainSection = styled.div`
  display: flex;
  position: relative;
  min-height: inherit;
  justify-content: end;
  align-items: center;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    display: none;
  }
`;

const MainLogo = styled.div`
  position: absolute;
  top: calc(50% + 20px);
  left: 50%;
  translate: -50% -50%;
  width: 554px;
  height: 495px;
  background: ${props =>
      `url(${require(`../../assets/images/home/kv-logo-${props.lang}.png`).default})`}
    center/contain no-repeat;

  @media screen and (max-width: ${BREAK_POINTS.xl}) {
    width: 500px;
    height: 470px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 400px;
    height: 360px;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    display: none;
  }
`;

const RightLinkSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: url(${rightSectionBg}) center right/contain no-repeat;
  padding: 100px 0;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    display: none;
  }
`;

const linkStyle = `
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
`;

const DownloadLink = styled.div`
  display: block;
  width: 275px;
  height: 100px;
  background: ${props =>
      `url(${require(`../../assets/images/home/side-menu-btn-${props.platform}.png`).default})`}
    center/contain no-repeat;
  margin-right: ${props => (props.platform === "ios" ? "-2px" : "-10px")};
  transition: all 0.2s ease-in;
  ${props =>
    props.platform === "pc"
      ? `
        color: #ffffff;
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        padding: 32px 45px 0 0;
      `
      : `
        ${linkStyle}
        line-height: 98px;
        padding-left: 65px;
      `};

  span {
    font-size: 12px;
    display: block;
    line-height: 10px;
  }

  &:hover {
    margin-right: ${props => (props.platform === "ios" ? "8px" : "0")};
  }
`;

const SNSLink = styled.div`
  display: block;
  width: 187px;
  height: 70px;
  background: ${props =>
      `url(${require(`../../assets/images/home/side-menu-snk-${props.sns}.png`).default})`}
    center/contain no-repeat;
  ${linkStyle};
  line-height: 68px;
  padding-left: 37px;
  transition: all 0.2s ease-in;

  &:hover {
    margin-right: 10px;
  }
`;

const MobileHome = styled.div`
  margin-top: calc(25vh - 64px);
  margin-bottom: 30px;

  > img {
    width: 70%;
    display: block;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const MobileHomeButtons = styled.div`
  width: 100vw;
  min-height: 55vw;
  background: url(${mobileBtnBg}) top center/105% no-repeat;
  margin-top: -20px;
  padding-top: 20px;
`;

const MobileHomeButtonsLine1 = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 38vw;
  }
`;

const MobileHomeButtonsLine2 = styled.div`
  width: 96%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 27vw;
  }

  #mobile-pc-link > img {
    width: 40vw;
    margin-bottom: 15px;
  }
`;

export {
  HomeContainer,
  TopLeftBanner,
  RightLinkSection,
  MainLogo,
  DownloadLink,
  SNSLink,
  MainSection,
  MobileHome,
  MobileHomeButtons,
  MobileHomeButtonsLine1,
  MobileHomeButtonsLine2,
};
