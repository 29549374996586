import styled from "@emotion/styled";
import { containerStyle, BREAK_POINTS } from "../../utils/style";

const BirthVerificationContainer = styled.div`
  ${containerStyle}
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #4a2a39e0;
  overflow: hidden;
  animation: fadeIn 0.5s;
`;

const BirthVerificationBlock = styled.div`
  width: 80%;
  max-width: 1000px;
  min-height: 536px;
  background-color: rgba(51, 1, 25, 0.88);
  padding: 55px 30px 20px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 90%;
    padding: 20px;
  }

  @media screen and (max-width: 350px) {
    padding: 15px;
  }
`;

const BirthVerificationWarningIcon = styled.img`
  width: 130px;
  display: block;
  margin: 0 auto 10px;

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 18%;
    min-width: 90px;
  }
`;

const BirthVerificationWarning = styled.div`
  width: 90%;
  color: #d85358;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 55px;

  > div:first-of-type {
    font-size: 58px;
    font-weight: 700;
  }

  > div:last-of-type {
    max-width: 70%;
    font-size: ${props => (props.lang === "en" ? "12px" : "20px")};
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-left: 15px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    > div:first-of-type {
      width: auto;
      min-width: auto;
    }

    > div:last-of-type {
      max-width: 60%;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;

    > div:first-of-type {
      font-size: 48px;
    }

    > div:last-of-type {
      max-width: 90%;
      width: 90%;
      padding-left: 0;
    }
  }
`;

const BirthButtonBlock = styled.div`
  width: 85%;
  margin: 0 auto 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 90%;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const BirthInputBlock = styled.div`
  width: 85%;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 90%;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const BirthLabel = styled.p`
  font-weight: 700;
  padding-bottom: 10px;
`;

const BirthInput = styled.input`
  height: 36px;
  width: 75%;
  margin: 10px 10px;
  text-align: center;

  ::placeholder {
    text-align: center;
    color: ${props => (props.error ? "#D04545" : "#606060")};
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 90%;
  }
`;

const BirthButton = styled.div`
  width: 221px;
  height: 60px;
  background-color: ${props => (props.type === "confirm" ? "#d30093" : "#8f8f8f")};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 48%;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    max-width: 240px;
    margin: 8px 0;
  }
`;

const BirthButtonText = styled.div`
  font-family: Noto Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;
`;

const BirthCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80%;
  max-width: 1000px;
  margin-bottom: 5px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 90%;
  }
`;

const BirthCloseButton = styled.button`
  width: 36px !important;
  height: 36px !important;
  top: -50px;
  right: -50px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 24px !important;
    height: 24px !important;
    top: 1.5%;
    right: 9%;
  }
`;

const BirthCloseButtonIcon = styled.img`
  width: 36px !important;
  height: 36px !important;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 24px !important;
    height: 24px !important;
    right: 0px;
  }
`;

const BirthVerificationFooter = styled.div`
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.6;
`;

export {
  BirthButton,
  BirthButtonBlock,
  BirthButtonText,
  BirthInput,
  BirthInputBlock,
  BirthLabel,
  BirthVerificationBlock,
  BirthVerificationContainer,
  BirthVerificationFooter,
  BirthVerificationWarning,
  BirthVerificationWarningIcon,
  BirthCloseButtonContainer,
  BirthCloseButton,
  BirthCloseButtonIcon,
};
