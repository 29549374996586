import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Link, useStaticQuery, graphql } from "gatsby";
import {
  CharacterListBg,
  CharacterListSection,
  CharacterListInnerSection,
  CharacterListSectionTitle,
  CharacterPreview,
} from "./style";
import { CHARACTER_TYPES, getLocaleFromPath } from "../../utils/utils";
import sectionBgLeft from "../../assets/images/char-list-pic-01.png";
import sectionBgRight from "../../assets/images/char-list-pic-02.png";

const getGroupedDataList = (dataList, groups = [3, 3]) => {
  let result = [[]];
  let group_index = 0;

  let index = 0;
  dataList.forEach(data => {
    if (index < groups[group_index]) {
      result[result.length - 1].push(data);
      index++;
    } else if (index === groups[group_index]) {
      index = 1;
      group_index = group_index === 0 ? 1 : 0;
      result.push([data]);
    }
  });

  return result;
};

const getGroups = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth >= 1300 ? [7, 6] : window.innerWidth >= 768 ? [5, 4] : [3, 3];
  }

  return [3, 3];
};

const CharacterGroupedList = ({ locale, title, characterGroups = [] }) => (
  <CharacterListSection>
    <CharacterListSectionTitle>{title}</CharacterListSectionTitle>
    {characterGroups.map((characterGroup, group_index) => {
      return (
        <CharacterListInnerSection key={group_index}>
          {characterGroup.map(character => {
            const { type, slug, thumbImg } = character;
            return (
              <Link
                key={`character_${type}_${slug}`}
                id={`char-url-${type}-${slug}`}
                to={`/${locale}/${type}/${slug}`}>
                <picture id={`char-preview-${type}-${slug}`}>
                  <source
                    srcSet={
                      require(`../../assets/images/characterListWebp${thumbImg.replace(
                        ".png",
                        ".webp"
                      )}`).default
                    }
                    type="image/webp"
                  />
                  <CharacterPreview
                    src={require(`../../assets/images/characterList${thumbImg}`).default}
                    alt="character-preview"
                  />
                </picture>
              </Link>
            );
          })}
        </CharacterListInnerSection>
      );
    })}
  </CharacterListSection>
);

export default function CharacterList() {
  // useStaticQuery does not accept variables
  // https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/#known-limitations
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "character" }, draft: { eq: false } } }
      ) {
        nodes {
          fileAbsolutePath
          frontmatter {
            type
            slug
            characterType
            order
            title
            thumbImg
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale } = intl;

  const { allMarkdownRemark } = data || {};
  const { nodes } = allMarkdownRemark || {};

  // get normal and sp characters
  let normal_characters = [];
  let sp_characters = [];

  (nodes || []).forEach(character => {
    const { fileAbsolutePath, frontmatter } = character;
    if (getLocaleFromPath(fileAbsolutePath) === locale) {
      if (frontmatter.characterType === CHARACTER_TYPES.normal) {
        normal_characters.push({ ...character.frontmatter });
      } else if (frontmatter.characterType === CHARACTER_TYPES.sp) {
        sp_characters.push({ ...character.frontmatter });
      }
    }
  });

  // sort characters by order (descending)
  normal_characters.sort((a, b) => parseInt(b.order) - parseInt(a.order));
  sp_characters.sort((a, b) => parseInt(b.order) - parseInt(a.order));

  const groups = getGroups();
  const normalGroup = getGroupedDataList(normal_characters, groups);
  const spGroup = getGroupedDataList(sp_characters, groups);

  return (
    <>
      <CharacterListBg>
        <img src={sectionBgLeft} alt="character-list-pattern" />
        <img src={sectionBgRight} alt="character-list-pattern" />
      </CharacterListBg>

      {normal_characters.length ? (
        <CharacterGroupedList
          locale={locale}
          title="- Characters -"
          characterGroups={normalGroup}
        />
      ) : null}
      {sp_characters.length ? (
        <CharacterGroupedList locale={locale} title="- SP Characters -" characterGroups={spGroup} />
      ) : null}
    </>
  );
}
