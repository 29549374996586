import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";

import {
  BirthButton,
  BirthButtonBlock,
  BirthButtonText,
  BirthInput,
  BirthInputBlock,
  BirthLabel,
  BirthVerificationBlock,
  BirthVerificationContainer,
  BirthVerificationFooter,
  BirthVerificationWarning,
  BirthVerificationWarningIcon,
  BirthCloseButtonContainer,
  BirthCloseButton,
  BirthCloseButtonIcon,
} from "./style";

import warnIcon from "../../assets/images/icon-warn.png";
import closeIcon from "../../assets/images/birth-btn-x.png";

export default function BirthVerification({ handleConfirm, handleDecline }) {
  const intl = useIntl();
  const { locale, formatMessage } = intl;

  const [birthYear, setBirthYear] = useState("");
  const [isError, setIsError] = useState(false);
  const [descType, setDescType] = useState("warning");

  // 3 sec is too fast
  const countdown = 3.8;

  useEffect(() => {
    if (descType === "forbidden") {
      let timer = setTimeout(handleDecline, countdown * 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [descType]);

  return (
    <>
      <BirthVerificationContainer>
        <BirthCloseButtonContainer>
          <BirthCloseButton onClick={handleDecline}>
            <BirthCloseButtonIcon src={closeIcon} alt="close" />
          </BirthCloseButton>
        </BirthCloseButtonContainer>
        <BirthVerificationBlock>
          <BirthVerificationWarningIcon src={warnIcon} alt="warn-icon" />
          <BirthVerificationWarning lang={locale}>
            <div>{formatMessage({ id: "birth-warning" })}</div>
            <div>{formatMessage({ id: "birth-warning-desc" })}</div>
          </BirthVerificationWarning>

          <BirthInputBlock>
            {descType === "warning" && (
              <>
                <BirthLabel>{formatMessage({ id: "birth-label" })}</BirthLabel>
                <BirthInput
                  type="number"
                  error={isError}
                  placeholder={formatMessage({ id: "birth-placeholder" })}
                  value={birthYear}
                  onChange={event => {
                    const value = Number.parseInt(event.target.value);
                    if (!Number.isNaN(value)) setIsError(false);
                    setBirthYear(value);
                  }}
                />
              </>
            )}
            {descType === "forbidden" && (
              <>
                <BirthLabel>{formatMessage({ id: "birth-forbidden" })}</BirthLabel>
                <BirthLabel>{formatMessage({ id: "birth-forbidden-desc" })}</BirthLabel>
              </>
            )}
          </BirthInputBlock>

          {descType === "warning" && (
            <>
              <BirthButtonBlock>
                <BirthButton
                  type="confirm"
                  onClick={() => {
                    const today = new Date();
                    const year = today.getFullYear();
                    const setError = () => {
                      setIsError(true);
                      setBirthYear("");
                    };

                    if (!birthYear) {
                      setError();
                      return;
                    }
                    if (birthYear > year) {
                      setError();
                      return;
                    }
                    if (year - birthYear > 100) {
                      setError();
                      return;
                    }
                    if (year - birthYear <= 19) {
                      setDescType("forbidden");
                      return;
                    }
                    if (year - birthYear > 19) {
                      handleConfirm();
                      return;
                    }
                  }}>
                  <BirthButtonText lang={locale}>
                    <div>{formatMessage({ id: "birth-confirm" })}</div>
                  </BirthButtonText>
                </BirthButton>
              </BirthButtonBlock>
            </>
          )}

          <BirthVerificationFooter>
            {formatMessage({ id: "birth-footer" })
              .split("\n")
              .map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
          </BirthVerificationFooter>
        </BirthVerificationBlock>
      </BirthVerificationContainer>
    </>
  );
}
