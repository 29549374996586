import React, { useContext } from "react";

import { VerificationContext } from "../contexts";

export default function Download({ href, text, children }) {
  const { setIsVerificationShow, setVerificationLink } = useContext(VerificationContext);

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      onClick={event => {
        event.preventDefault();
        setVerificationLink(href);
        setIsVerificationShow(true);
      }}>
      {text || children}
    </a>
  );
}
